/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import {
  Container, Row, Col, Button
} from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  Layout, FormikInput, FormikSelect, FormikCheckbox
} from '../Components';

const RegistrationForm = () => {
  const [formResponse, setFormResponse] = useState('');
  const [selectedOption, setSelectedOption] = useState(0);
  const { executeRecaptcha } = useGoogleReCaptcha();
  return !formResponse ? (
    <Formik
      initialValues={{
        program: 'Culture',
        type: 'Association',
        appel: 'Coopération Européenne'
      }}
      validationSchema={Yup.object().shape({
        program: Yup.string().required('Ce champs est requis'),
        appel: Yup.string().required('Ce champs est requis'),
        organization: Yup.string().required('Ce champs est requis'),
        type: Yup.string().required('Ce champs est requis'),
        activity_type: Yup.array().of(Yup.string()).required('Ce champs est requis'),
        creation_date: Yup.date().nullable(),
        pays: Yup.array().of(Yup.string()).required('Ce champs est requis'),
        code_postal: Yup.number('Code postal invalide').required('Ce champs est requis'),
        contact: Yup.string().required('Ce champs est requis'),
        role: Yup.string().required('Ce champs est requis'),
        contact_email: Yup.string().required('Ce champs est requis'),
        contact_phone: Yup.string().required('Ce champs est requis'),
        description: Yup.string()
          .min(200, 'Votre description doit contenir au minimum 200 caractères')
          .nullable(),
        presentation: Yup.string()
          .min(150, 'Votre présentation doit contenir au minimum 150 caractères')
          .nullable(),
        website: Yup.string()
          .url("URL invalide, merci de mettre l'adresse en entier")
          .required('Ce champs est requis'),
        contact_facebook: Yup.string()
          .url("URL invalide, merci de mettre l'adresse en entier")
          .required('Ce champs est requis'),
        registration: Yup.string().required('Ce champs est requis')
      })}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        const formData = new FormData();
        const captchaResponse = await executeRecaptcha('contact');
        const formValues = {
          ...values,
          _wpcf7_recaptcha_response: captchaResponse
        };
        for (const key of Object.keys(formValues)) {
          formData.append(key, formValues[key]);
        }
        axios({
          method: 'post',
          url: '/api/wp-json/contact-form-7/v1/contact-forms/384/feedback',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
          .then((res) => {
            setStatus({ success: true });
            setFormResponse(res.data.message);
            setSubmitting(false);
          })
          .catch((res) => {
            console.error(res);
          });
      }}
      render={({ isSubmitting, setFieldValue }) => (
        <Form className="contactForm">
          <Row>
            <Col md="6">
              <Field
                name="program"
                id="program"
                required
                label="Sous-programme *"
                autoComplete="off"
                inputprops={{
                  options: ['Culture', 'Media']
                }}
                component={FormikSelect}
              />
              <Field
                name="appel"
                id="appel"
                required
                label="Appel à projet  *"
                autoComplete="off"
                inputprops={{
                  options: [
                    'Coopération Européenne',
                    'Traduction littéraire',
                    'Accès aux marchés',
                    'Education au cinéma'
                  ]
                }}
                component={FormikSelect}
              />
              <Field
                type="text"
                name="organization"
                id="organization"
                required
                label="Nom de l’organisation *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                name="type"
                id="type"
                required
                label="Type d’organisation  *"
                autoComplete="off"
                inputprops={{
                  options: ['Association', 'Entreprise', 'Structure publique', 'Autre']
                }}
                component={FormikSelect}
              />
              <Field
                type="text"
                name="activity_type"
                id="activity_type"
                label="Secteur d’activité *"
                required
                multiple
                setFieldValue={setFieldValue}
                inputprops={{
                  options: [
                    'Théâtre',
                    'Musique',
                    'Danse',
                    'Opéra',
                    'Arts du cirque',
                    'Arts de la rue',
                    'Art de la marionnettes',
                    'Culture tangible - sites historiques et bâtiments',
                    'Culture tangible - Musées',
                    'Culture tangible - bibliothèques et archives',
                    'Culture intangible',
                    'Peinture, dessin',
                    'Arts graphiques',
                    'Photographie',
                    'Sculpture',
                    'Arts numériques',
                    'Films, vidéos',
                    'Arts décoratifs',
                    'Design graphique',
                    'Design de mode',
                    "Artisanat d'arts",
                    'Ecriture créative',
                    'Traduction',
                    'Edition',
                    'Architecture',
                    'Autres'
                  ]
                }}
                component={FormikSelect}
              />
              <Field
                type="date"
                required
                name="creation_date"
                id="creation_date"
                label="Date de création *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                name="pays"
                id="pays"
                label="Pays *"
                required
                multiple
                setFieldValue={setFieldValue}
                inputprops={{
                  defaultOption: 'TUNISIA',
                  options: [
                    'REPUBLIC OF ALBANIA',
                    'ARMENIA',
                    'AUSTRIA',
                    'BELGIUM',
                    'BOSNIA AND HERZEGOVINA',
                    'BULGARIA',
                    'CROATIA',
                    'REPUBLIC OF CYPRUS',
                    'CZECH REPUBLIC',
                    'DENMARK',
                    'ESTONIA',
                    'FINLAND',
                    'France',
                    'NORTH MACEDONIA',
                    'GEORGIA',
                    'GERMANY',
                    'GREECE',
                    'HUNGARY',
                    'ICELAND',
                    'IRELAND',
                    'ITALY',
                    'KOSOVO',
                    'LATVIA',
                    'LITHUANIA',
                    'Luxembourg',
                    'MALTA',
                    'MOLDOVA',
                    'MONTENEGRO',
                    'THE NETHERLANDS',
                    'NORWAY',
                    'POLAND',
                    'Portugal',
                    'ROMANIA',
                    'REPUBLIC OF SERBIA',
                    'SLOVAKIA',
                    'SLOVENIA',
                    'SPAIN',
                    'SWEDEN',
                    'UNITED KINGDOM',
                    'UKRAINE',
                    'Pas de préférence'
                  ]
                }}
                component={FormikSelect}
              />
              <Field
                required
                type="text"
                name="adresse"
                id="adresse"
                label="Adresse *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="number"
                name="code_postal"
                id="code_postal"
                label="Code postal"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                required
                type="text"
                name="ville"
                id="ville"
                label="Ville *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="text"
                name="gouvernorat"
                id="gouvernorat"
                label="Gouvernorat/Etat"
                autoComplete="off"
                component={FormikInput}
              />
            </Col>
            <Col md="6">
              <Field
                required
                type="text"
                name="contact"
                id="contact"
                label="Nom de la personne de contact *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                required
                type="text"
                name="role"
                id="role"
                label="Rôle dans l'organisation *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                required
                type="textarea"
                name="contact_email"
                id="contact_email"
                label="Emails de contact *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                required
                type="textarea"
                name="contact_phone"
                id="contact_phone"
                label="Téléphones de contact *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="url"
                name="website"
                id="website"
                label="Site web *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="url"
                name="contact_facebook"
                id="contact_facebook"
                label="Page Facebook *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                required
                type="textarea"
                name="presentation"
                id="presentation"
                label="Présentation de l’organisation *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                name="registration"
                id="registration"
                required
                label="Type d'inscription *"
                autoComplete="off"
                onChange={(value) => {
                  if (value === 'Je cherche des partenaires pour mon projet') {
                    setSelectedOption(1);
                  } else if (value === 'Je souhaite intégrer un projet en tant que partenaire') {
                    setSelectedOption(2);
                  } else {
                    setSelectedOption(0);
                  }
                }}
                inputprops={{
                  defaultOption: ' ',
                  options: [
                    'Je cherche des partenaires pour mon projet',
                    'Je souhaite intégrer un projet en tant que partenaire'
                  ]
                }}
                component={FormikSelect}
              />
              {selectedOption === 1 && (
                <>
                  <Field
                    type="text"
                    name="project"
                    id="project"
                    label="Nom du projet *"
                    autoComplete="off"
                    component={FormikInput}
                  />
                  <Field
                    type="textarea"
                    name="description"
                    id="description"
                    label="Description du projet *"
                    autoComplete="off"
                    component={FormikInput}
                  />
                  <Field
                    required
                    type="text"
                    name="sector_partner"
                    id="sector_partner"
                    label="Secteur d'activité du partenaire recherché *"
                    multiple
                    setFieldValue={setFieldValue}
                    inputprops={{
                      options: [
                        'Théâtre',
                        'Musique',
                        'Danse',
                        'Opéra',
                        'Arts du cirque',
                        'Arts de la rue',
                        'Art de la marionnettes',
                        'Culture tangible - sites historiques et bâtiments',
                        'Culture tangible - Musées',
                        'Culture tangible - bibliothèques et archives',
                        'Culture intangible',
                        'Peinture, dessin',
                        'Arts graphiques',
                        'Photographie',
                        'Sculpture',
                        'Arts numériques',
                        'Films, vidéos',
                        'Arts décoratifs',
                        'Design graphique',
                        'Design de mode',
                        "Artisanat d'arts",
                        'Ecriture créative',
                        'Traduction',
                        'Edition',
                        'Architecture',
                        'Autres'
                      ]
                    }}
                    component={FormikSelect}
                  />
                  <Field
                    required
                    type="textarea"
                    name="profile_partner"
                    id="profile_partner"
                    label="Profil du partenaire recherché *"
                    autoComplete="off"
                    component={FormikInput}
                  />
                  <Field
                    name="country_partner"
                    id="country_partner"
                    label="Pays du partenaire *"
                    required
                    multiple
                    setFieldValue={setFieldValue}
                    inputprops={{
                      defaultOption: 'TUNISIA',
                      options: [
                        'REPUBLIC OF ALBANIA',
                        'ARMENIA',
                        'AUSTRIA',
                        'BELGIUM',
                        'BOSNIA AND HERZEGOVINA',
                        'BULGARIA',
                        'CROATIA',
                        'REPUBLIC OF CYPRUS',
                        'CZECH REPUBLIC',
                        'DENMARK',
                        'ESTONIA',
                        'FINLAND',
                        'France',
                        'NORTH MACEDONIA',
                        'GEORGIA',
                        'GERMANY',
                        'GREECE',
                        'HUNGARY',
                        'ICELAND',
                        'IRELAND',
                        'ITALY',
                        'KOSOVO',
                        'LATVIA',
                        'LITHUANIA',
                        'Luxembourg',
                        'MALTA',
                        'MOLDOVA',
                        'MONTENEGRO',
                        'THE NETHERLANDS',
                        'NORWAY',
                        'POLAND',
                        'Portugal',
                        'ROMANIA',
                        'REPUBLIC OF SERBIA',
                        'SLOVAKIA',
                        'SLOVENIA',
                        'SPAIN',
                        'SWEDEN',
                        'UNITED KINGDOM',
                        'UKRAINE',
                        'Pas de préférence'
                      ]
                    }}
                    component={FormikSelect}
                  />
                  <Field
                    type="textarea"
                    name="existing_partner"
                    id="existing_partner"
                    label="Partenaires existants"
                    autoComplete="off"
                    component={FormikInput}
                  />
                </>
              )}
              {selectedOption === 2 && (
                <>
                  <Field
                    type="textarea"
                    required
                    name="issues"
                    id="issues"
                    label="Quels sont les problématiques sur lesquelles votre organisation souhaitent travailler en 2020-2021? *"
                    autoComplete="off"
                    component={FormikInput}
                  />
                  <Field
                    type="textarea"
                    required
                    name="project_type"
                    id="project_type"
                    label="Quel type de projet souhaitez-vous intégrer comme partenaire? *"
                    autoComplete="off"
                    component={FormikInput}
                  />
                  <Field
                    required
                    type="text"
                    name="sector_partner"
                    id="sector_partner"
                    label="Secteur(s) d'activité des partenaires potentiels *"
                    inputprops={{
                      options: [
                        'Théâtre',
                        'Musique',
                        'Danse',
                        'Opéra',
                        'Arts du cirque',
                        'Arts de la rue',
                        'Art de la marionnettes',
                        'Culture tangible - sites historiques et bâtiments',
                        'Culture tangible - Musées',
                        'Culture tangible - bibliothèques et archives',
                        'Culture intangible',
                        'Peinture, dessin',
                        'Arts graphiques',
                        'Photographie',
                        'Sculpture',
                        'Arts numériques',
                        'Films, vidéos',
                        'Arts décoratifs',
                        'Design graphique',
                        'Design de mode',
                        "Artisanat d'arts",
                        'Ecriture créative',
                        'Traduction',
                        'Edition',
                        'Architecture',
                        'Autres'
                      ]
                    }}
                    component={FormikSelect}
                  />
                  <Field
                    name="country_partner"
                    id="country_partner"
                    label="Pays des partenaires potentiels *"
                    required
                    inputprops={{
                      defaultOption: 'TUNISIA',
                      options: [
                        'REPUBLIC OF ALBANIA',
                        'ARMENIA',
                        'AUSTRIA',
                        'BELGIUM',
                        'BOSNIA AND HERZEGOVINA',
                        'BULGARIA',
                        'CROATIA',
                        'REPUBLIC OF CYPRUS',
                        'CZECH REPUBLIC',
                        'DENMARK',
                        'ESTONIA',
                        'FINLAND',
                        'France',
                        'NORTH MACEDONIA',
                        'GEORGIA',
                        'GERMANY',
                        'GREECE',
                        'HUNGARY',
                        'ICELAND',
                        'IRELAND',
                        'ITALY',
                        'KOSOVO',
                        'LATVIA',
                        'LITHUANIA',
                        'Luxembourg',
                        'MALTA',
                        'MOLDOVA',
                        'MONTENEGRO',
                        'THE NETHERLANDS',
                        'NORWAY',
                        'POLAND',
                        'Portugal',
                        'ROMANIA',
                        'REPUBLIC OF SERBIA',
                        'SLOVAKIA',
                        'SLOVENIA',
                        'SPAIN',
                        'SWEDEN',
                        'UNITED KINGDOM',
                        'UKRAINE',
                        'Pas de préférence'
                      ]
                    }}
                    component={FormikSelect}
                  />
                </>
              )}
              <Field
                type="checkbox"
                name="authorization"
                id="authorization"
                required
                value="yes"
                label="J’autorise le Desk Europe Créative Tunisie à diffuser ces informations dans le but de faciliter les partenariats avec mon organisation"
                component={FormikCheckbox}
              />
              <Field
                type="checkbox"
                name="newsletter"
                id="newsletter"
                value="yes"
                label="Je souhaite recevoir la newsletter Europe Créative Tunisie pour rester informé des opportunités"
                component={FormikCheckbox}
              />
            </Col>
            <Col md="12">
              <Button
                className="contactForm__submit contactPage__submit mx-auto d-block px-5"
                color="success"
                type="submit"
                disabled={isSubmitting}
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    />
  ) : (
    <Row className="align-middle">
      <Col xs="12">
        <p className="text-center">{formResponse}</p>
        <Button onClick={() => setFormResponse('')} color="link" className="d-block mx-auto">
          Fermer
        </Button>
      </Col>
    </Row>
  );
};
const Inscription = () => (
  <Layout>
    <Container>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} md="12">
          <RegistrationForm />
        </Col>
      </Row>
    </Container>
  </Layout>
);
export default Inscription;
